<template>
  <Top />
  <div class="nav">
    <div class="left">
      <div class="pictrue"><img :src="userinfo.avatar" alt="" /></div>
      <div class="info">
        <div class="name">{{ userinfo.nickname }}</div>
        <div class="userId">UID: {{ userinfo.userId }}</div>
      </div>
    </div>
    <div class="right" @click="ret">
      <img src="@/assets/img/return.png" alt="" />
    </div>
  </div>
  <div style="background-color: #f6f6f8">
    <div class="main">
      <div
        class="tab"
        v-if="
          1 ||
            userinfo.talentCardExpiredTime == null ||
            timeDifferenceInMilliseconds <= 0
        "
      >
        <div
          class="ordinary"
          :class="goldId == item.type ? 'act-ordinary' : 'ordinary'"
          v-for="item in type"
          :key="item.type"
          @click="check(item)"
        >
          {{ item.name }}
        </div>
      </div>
      <div>
        <div style="height: calc(100vh - 3.8rem); overflow: auto">
          <div class="become_login">
            <div
              class="type_login"
              v-for="role in types_login"
              :key="role.type"
            >
              <div class="type_top"></div>
              <div class="card_login">
                <div class="card_logo">
                  <img
                    :src="
                      role.type == 1
                        ? card_logo.imageSrc
                        : card_logo.otherImageSrc
                    "
                    alt=""
                  />
                </div>
                <div
                  class="card_name"
                  :style="{ color: role.type == 1 ? '#5EB15C' : '#ED931E' }"
                >
                  {{ role.name }}
                </div>
              </div>
              <!-- <div class="multiple" :innerText="role.type == 1 ? '1.3倍固定收益，更多灵活奖励' : '1.5倍固定收益，更多灵活奖励'"></div> -->
              <div class="task" v-for="task in role.equityList" :key="task">
                <div class="completion">
                  <div class="complete">
                    <img
                      :src="
                        role.type == 1 ? bingo.imageSrc : bingo.otherImageSrc
                      "
                      alt=""
                    />
                  </div>
                  <div class="task_describe">{{ task }}</div>
                </div>
                <!-- <p v-if="task.p == '' ? false : true">{{ task.p }}</p>
                <a style="font-size: 0.14rem;margin-left: 0.26rem;" class="view" v-if="task.a == '' ? false : true"
                    href="">{{ task.a }}</a> -->
              </div>
            </div>
          </div>
          <div class="check_pay">
            <div class="type_pay" v-for="level in money" :key="level.type">
              <div class="pay_type" v-for="item in level.list" :key="item">
                <div class="card_type">
                  <div
                    class="card_name"
                    :style="{ color: level.type == 1 ? '#5EB15C' : '#ED931E' }"
                  >
                    {{ item.cardName }}
                  </div>
                  <div class="card_time">{{ item.days }}{{ lang.v.days }}</div>
                </div>
                <div class="money">
                  <div class="left_money">
                    <span
                      style="
                        height: 0.21rem;
                        font-size: 0.16rem;
                        font-family: MiSans, MiSans;
                        font-weight: 600;
                        color: #444444;
                        line-height: 0.21rem;
                      "
                    >{{ lang.v.unit }}</span
                    >
                    <span
                      style="
                        height: 0.42rem;
                        font-size: 0.32rem;
                        font-family: MiSans, MiSans;
                        font-weight: 600;
                        color: #444444;
                        line-height: 0.42rem;
                      "
                    >{{ item.price }}</span
                    >
                    <span
                      style="
                        height: 0.21rem;
                        font-size: 0.16rem;
                        font-family: MiSans, MiSans;
                        font-weight: 400;
                        color: #444444;
                        line-height: 0.21rem;
                      "
                    >/{{ item.originalPrice }}</span
                    >
                  </div>
                  <div
                    @click="choose(item)"
                    class="check_it"
                    :innerText="check_id == item.id ? Selected : Select"
                    :style="{
                      background:
                        level.type == 1
                          ? check_id == item.id
                            ? 'rgba(133, 140, 159, 0.10)'
                            : '#5EB15C'
                          : check_id == item.id
                            ? 'rgba(133, 140, 159, 0.10)'
                            : '#ED931E',
                      color:
                        check_id == item.id
                          ? 'rgba(133, 140, 159, 1)'
                          : '#FFFFFF',
                      border:
                        check_id == item.id
                          ? '0.01rem solid rgba(133,140,159,0.1)'
                          : '',
                    }"
                  >
                    {{ item.check }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pay_method" v-if="money_pay.money == '' ? false : true">
            <div class="title">{{ lang.v.method }}</div>
            <div class="payment">
              <div
                :class="{ box: true, box_zfb: nowIndex === index }"
                style="margin: 0 2px"
                v-for="(pay, index) in pays"
                :key="pay.id"
                @click="check_pay(pay)"
              >
                <div v-if="pay.url" class="pay_logo">
                  <img :src="pay.url" alt="" />
                </div>
                <div class="pay_name">{{ pay.name }}</div>
              </div>
            </div>
            <div class="pay">{{ lang.v.paid }}</div>
            <div class="pay_money">{{ lang.v.unit }}{{ money_pay.money }}</div>
            <div class="zfb_meet">
              <div class="fork" v-if="zhifu" @click="paychart">
                <div class="skip" v-if="zhifuwz">
                  <img src="@/assets/img/skip.png" alt="" />
                </div>
                <div class="text">
                  {{ zhifuwz ? lang.v.jump : lang.v.gopay }}
                </div>
              </div>
              <div class="fork" v-else>
                <van-loading type="spinner" size="0.24rem" color="#FFFFFF" />
              </div>
            </div>
          </div>
          <div
            class="pay_method"
            v-if="money_pay_senior.money == '' ? false : true"
          >
            <div class="title">{{ lang.v.method }}</div>
            <div class="payment">
              <div
                :class="{ box: true, box_zfb: nowIndex === index }"
                style="margin: 0 2px"
                v-for="(pay, index) in pays"
                :key="pay.id"
                @click="check_pay(pay)"
              >
                <div v-if="pay.url" class="pay_logo">
                  <img :src="pay.url" alt="" />
                </div>
                <div class="pay_name">{{ pay.name }}</div>
              </div>
            </div>
            <div class="pay">{{ lang.v.paid }}</div>
            <div class="pay_money">
              {{ lang.v.unit }}{{ money_pay_senior.money }}
            </div>
            <div class="zfb_meet">
              <div class="fork" v-if="zhifu" @click="paychart">
                <div class="skip" v-if="zhifuwz">
                  <img src="@/assets/img/skip.png" alt="" />
                </div>
                <div class="text">
                  {{ zhifuwz ? lang.v.jump : lang.v.gopay }}
                </div>
              </div>
              <div class="fork" v-else>
                <van-loading type="spinner" size="0.24rem" color="#FFFFFF" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <van-overlay :show="dialogVisible">
    <div class="wrapper">
      <div class="block" @click.stop>
        <div class="payment-form">
          <div class="title">{{ lang.v.Payment }}</div>
          <div class="container">
            <div class="tips">
              {{ lang.v.will }} {{ email }}{{ lang.v.setCode }}
            </div>
            <div class="input-container">
              <input
                type="number"
                placeholder="Enter verification code"
                v-model="captcha"
              />
              <span
                class="coder"
                @click="getCheckCode"
                :disabled="payState.codeFetching"
              >{{ text }}</span
              >
            </div>
            <div class="input-container" style="margin-top: 1em">
              <input
                type="couponCode"
                placeholder="Enter coupon code"
                v-model="coupon"
                @input="validateCoupon"
                maxlength="15"
              />
            </div>
            <div class="pay-info">
              {{ tips }}
            </div>
          </div>
          <span class="dialog-footer">
            <button
              @click="payCancel"
              class="cancel"
              style="border-radius: 0.15rem; width: 0.8rem"
            >
              {{ lang.v.cancel }}
            </button>
            <button
              @click="paySubmit"
              class="submit"
              style="
                margin-left: 0.2rem;
                background-color: #ed931e;
                color: #eee;
                border-radius: 0.15rem;
                width: 2.1rem;
              "
            >
              {{ lang.v.confirmPay.replace('$y', payInfo.thirdPay) }}
            </button>
          </span>
        </div>
      </div>
    </div>
  </van-overlay>
  <van-overlay :show="show" @click="show = false">
    <div class="wrapper">
      <div class="block" @click.stop>
        <div
          style="
            display: flex;
            justify-content: center;
            padding-left: 0.14rem;
            padding-right: 0.14rem;
          "
        >
          <div class="channels">
            <span
              class="item"
              @click="onSelectChannel(item)"
              v-for="item in pay_type"
              :key="item.id"
              :class="{ active: item === selectChannel }"
            >
              <img v-show="item.icon" :src="item.icon" class="logo" />
              <span class="name">{{ item.name }}</span>
            </span>
          </div>
        </div>
        <div class="form-container" v-if="selectChannel.form">
          <div class="title">{{ lang.v.InformationFilling }}</div>
          <el-form
            class="channel-form"
            ref="channelFormRef"
            :model="channelForm"
            :rules="selectChannel.form.rules"
          >
            <el-form-item
              v-for="item in selectChannel.form.items"
              :key="item.props"
              :prop="item.props"
              :required="item.required"
            >
              <el-input
                class="channel-input"
                v-model="channelForm[item.props]"
                maxlength="120"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="payment-form">
          <div class="title">{{ lang.v.BalanceCombinationPayment }}</div>
          <label class="amount-info">
            <span>{{ lang.v.AvailableBalance }} {{ state.wallet }}</span>
            <span class="checkbox-container">
              <input
                type="checkbox"
                checked
                :disabled="isBalance"
                @click="onCheckbox"
                v-model="state.useMixPay"
              />
              <span class="checkbox-checkmark" v-if="!isBalance"></span>
            </span>
          </label>
          <div v-if="state.useMixPay">
            <div class="tips">
              {{ lang.v.will }} {{ email }}{{ lang.v.setCode }}
            </div>
            <div class="input-container">
              <input
                type="number"
                placeholder="Enter verification code"
                v-model="captcha"
              />
              <span
                class="coder"
                @click="getCheckCode"
                :disabled="payState.codeFetching"
              >{{ text }}</span
              >
            </div>
            <div class="pay-info">
              {{ lang.v.BalanceDeductions.replace('$y', payInfo.wallet) }}
            </div>
          </div>
          <div class="input-container" style="margin-top: 1em">
            <input
              type="couponCode"
              placeholder="Enter coupon code"
              v-model="coupon"
              @input="validateCoupon"
              maxlength="15"
            />
          </div>
          <div class="pay-info">
            {{ tips }}
          </div>
          <div class="gopay" @click="gopay">
            {{ lang.v.goPay.replace('$y', payInfo.thirdPay) }}
          </div>
        </div>
      </div>
    </div>
  </van-overlay>
  <van-overlay :show="showUSTD" @click="showUSTD = false">
    <div class="wrapper">
      <div class="block" @click.stop>
        <div
          style="
            display: flex;
            justify-content: center;
            padding-left: 0.14rem;
            padding-right: 0.14rem;
          "
        >
          <div class="channels">
            <span class="item active">
              <span class="name">USTD</span>
            </span>
          </div>
        </div>
        <div class="form-container" v-if="selectChannel.form">
          <div class="title">{{ lang.v.InformationFilling }}</div>
          <el-form
            class="channel-form"
            ref="channelFormRef"
            :model="channelForm"
            :rules="selectChannel.form.rules"
          >
            <el-form-item
              v-for="item in selectChannel.form.items"
              :key="item.props"
              :prop="item.props"
              :required="item.required"
            >
              <el-input
                class="channel-input"
                v-model="channelForm[item.props]"
                maxlength="120"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="coinTypeSelect" v-if="coinType.length">
          <el-select
            v-model="coinTypeSelect"
            :placeholder="lang.v.pleaSelectCoinType"
            size="small"
            style="width: 100%"
          >
            <el-option
              v-for="item in coinType"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </div>
        <div class="payment-form">
          <div class="title">{{ lang.v.BalanceCombinationPayment }}</div>
          <label class="amount-info">
            <span>{{ lang.v.AvailableBalance }} {{ state.wallet }}</span>
            <span class="checkbox-container">
              <input
                type="checkbox"
                checked
                :disabled="isBalance"
                @click="onCheckbox"
                v-model="state.useMixPay"
              />
              <span class="checkbox-checkmark" v-if="!isBalance"></span>
            </span>
          </label>
          <div v-if="state.useMixPay">
            <div class="tips">
              {{ lang.v.will }} {{ email }}{{ lang.v.setCode }}
            </div>
            <div class="input-container">
              <input
                type="number"
                placeholder="Enter verification code"
                v-model="captcha"
              />
              <span
                class="coder"
                @click="getCheckCode"
                :disabled="payState.codeFetching"
              >{{ text }}</span
              >
            </div>
            <div class="pay-info">
              {{ lang.v.BalanceDeductions.replace('$y', payInfo.wallet) }}
            </div>
          </div>
          <div class="input-container" style="margin-top: 1em">
            <input
              type="couponCode"
              placeholder="Enter coupon code"
              v-model="coupon"
              @input="validateCoupon"
              maxlength="15"
            />
          </div>
          <div class="pay-info">
            {{ tips }}
          </div>
          <div class="gopay" @click="gopay">
            {{ lang.v.goPay.replace('$y', UstdCount) }}
          </div>
        </div>
      </div>
    </div>
  </van-overlay>
  <van-dialog
    v-model:show="showInvitaCode"
    :title="lang.v.Fill"
    @close="onCloseCodeDialog"
    @confirm="confirm"
    :confirm-button-text="lang.v.confirm"
    confirm-button-color="rgba(237, 147, 30, 1)"
  >
    <div class="dialog-box">
      <input
        type="text"
        v-model="addcode.invitationCode"
        :placeholder="lang.v.pleaEnterInviteCode"
        v-on:paste="paste"
      />
    </div>
  </van-dialog>
</template>
<script setup>
import { lang } from '@/lang';
import Top from '@/components/Top/top.vue';
import { savePayObject, getObject } from '@/utils/saveToLocal';
import { computed, onMounted, reactive, ref, watch } from 'vue';
import {
  card_info,
  card_pay,
  verificationCoupon,
  charge,
  checkCodeRequest_wallet_pay,
  platform,
  UstdPayInfo,
  personal,
  getUserInfo,
  inviteInfo,
  invitebind,
} from '@/api/fetcher';
import router from '@/router';
import message from '@/utils/message';
// 邀请代码
const addcode = reactive({
  invitationCode: '',
});
//选择支付方式
const nowIndex = ref(0);
const dialogVisible = ref(false);
const show = ref(false);

//验证码
const captcha = ref('');
//优惠劵
const coupon = ref('');
const tips = ref('');

// 计算USTD
const UstdCount = computed(() => {
  return (Number(payInfo.thirdPay) / Number(usdtInfo.exchangeRate)).toFixed(2);
});
//USTD
const showUSTD = ref(false);
const usdtInfo = reactive({
  exchangeRate: '',
  minPayAmount: '',
});
/* 优惠劵校验 */
const validateCoupon = async () => {
  if (coupon.value.length === 15) {
    const { data } = await verificationCoupon(coupon.value);
    if (data?.data) {
      const { talentCardType, status, amount } = data?.data;
      if (goldId.value != talentCardType || status) {
        tips.value = lang.v.couponState;
        state.coupon = 0;
      } else {
        state.coupon = amount;
        tips.value = lang.v.couponUse.replace('$y', amount);
      }
    } else {
      tips.value = lang.v.couponNo;
      state.coupon = 0;
    }
  } else {
    tips.value = lang.v.coupinErr;
    state.coupon = 0;
  }
};

// 达人卡的logo
const Selected = lang.v.Selected;
const Select = lang.v.Select;
const card_logo = {
  imageSrc: require('@/assets/img/ordinary.png'),
  otherImageSrc: require('@/assets/img/senior.png'),
};
// 黄，绿 的钩子
const bingo = {
  imageSrc: require('@/assets/img/ordinary_complete.png'),
  otherImageSrc: require('@/assets/img/senior_complete.png'),
};
let goldId = ref('');
let check_id = ref('');
let payment = ref('1');
const userinfo = ref({});
const platformType = ref({});

const ret = () => {
  router.go(-1);
};
var timeDifferenceInMilliseconds = ref();
var currentTime = new Date();
if (userinfo.value.vipExpiredTime != null) {
  timeDifferenceInMilliseconds.value = Math.abs(
    userinfo.value.vipExpiredTime - currentTime
  );
}
const zhifu = ref(true);
const zhifuwz = ref(0);
const text = ref('Get code');
const pay_type = ref([]);

const onSelectChannel = item => {
  if (selectChannel.value === item) {
    return;
  }
  selectChannel.value = item;
  state.minThirdPay = item.minAmount;
  sessionStorage.setItem('platId', item.id);
};
const warning10 = lang.v.warning10;
const onCheckbox = () => {
  state.useMixPay = !state.useMixPay;
};
const showInvitaCode = ref(false);
const gopay = async () => {
  let comboPay = false;

  if (state.useMixPay) {
    comboPay = true;
  }
  try {
    if (channelFormRef.value) {
      const ret = await channelFormRef.value.validate();
      if (!ret) {
        return;
      }
    }
  } catch (error) {
    return;
  }
  if (!sessionStorage.getItem('platId') && zhifuwz.value == 1) {
    message.warning(warning10);
  } else {
    const payType = sessionStorage.getItem('platId');
    zhifu.value = false;
    const formItems = selectChannel.value.form
      ? selectChannel.value.form.items
      : [];
    const extra = formItems.reduce((ret, item) => {
      const theValue = channelForm.value[item.props];
      if (theValue) {
        ret[item.props] = theValue.trim();
      }
      return ret;
    }, {});
    if(coinType.value.length){
      if(!coinTypeSelect.value){
        message.warning(lang.v.pleaSelectCoinType);
        zhifu.value = true;
        return
      }
      extra.coinType = coinTypeSelect.value;
    }
    await charge({
      couponCode: coupon.value,
      id: pay_money_id.value,
      rechargeType: 'TALENT_CARD',
      payType: 'H5',
      platformType: zhifuwz.value == 2 ? 'USDT' : 'THIRD_PARTY',
      thirdPartyPayPlatformId: zhifuwz.value == 1 ? payType : '',
      comboPay,
      captcha: comboPay ? captcha.value : null,
      extra,
    })
      .then(async res => {
        if (res) {
          message.success(lang.v.optSuccess);
          const data = JSON.parse(res.params);
          const payUrl = data.payUrl;
          window.sessionStorage.setItem('mch_order_id', res.orderNo);
          window.sessionStorage.setItem('rechargeType', 1);
          window.location.href = payUrl;
          router.push('/paystatus');
          zhifu.value = true;
          show.value = false;
        } else {
          zhifu.value = true;
          show.value = false;
        }
        sessionStorage.removeItem('payType');
        captcha.value = '';
      })
      .catch(() => {
        zhifu.value = true;
        show.value = false;
      });
  }
};

const selectChannel = ref({});
const channelForm = ref({});
const channelFormRef = ref();

//处理金额显示
const state = reactive({
  useMixPay: false,
  coupon: 0,
  minThirdPay: 0,
  wallet: 0,
  bill: 0,
});
const payInfo = reactive({ wallet: 0, thirdPay: 0 });

watch(
  state,
  () => {
    if (state.useMixPay) {
      if (state.wallet >= state.bill) {
        payInfo.wallet = state.bill - state.coupon - state.minThirdPay;
        payInfo.thirdPay = state.minThirdPay;
      } else {
        const diff = state.bill - state.wallet - state.coupon;
        if (diff >= state.minThirdPay) {
          payInfo.thirdPay = diff;
          payInfo.wallet = state.bill - state.coupon - diff;
        } else {
          payInfo.thirdPay = state.minThirdPay;
          payInfo.wallet = state.bill - state.coupon - state.minThirdPay;
        }
      }
    } else {
      const diff = state.bill - state.coupon;
      if (diff >= state.minThirdPay) {
        payInfo.thirdPay = diff;
      } else {
        payInfo.thirdPay = state.minThirdPay;
      }
      payInfo.wallet = 0;
    }
  },
  { deep: true }
);

watch(
  channelForm,
  newValue => {
    savePayObject('payInfo', newValue);
  },
  { deep: true }
);

let isBalance = false;
const price = ref(0);
const coinType = ref([])
const coinTypeSelect = ref()
const paychart = async () => {
  price.value = +currentCard.price;
  state.bill = price.value;
  if (!parentInvitationCode.value) {
    showInvitaCode.value = true;
    return;
  }

  if (zhifuwz.value == 1) {
    zhifu.value = false;
    state.useMixPay = false;
    await platform({
      amount: currentCard.price,
    })
      .then(async res => {
        console.log(res);
        if (res.length === 0) {
          zhifu.value = true;
          return message.error(lang.v.noPaymentChannel);
        }
        // todo 这里最好做个判断，如果没有通道提示用户暂无支付通道
        res.forEach(item => {
          if (item.params) {
            const formItems = JSON.parse(item.params);
            const rules = {};
            const items = formItems.map(formItem => {
              if (formItem.exp) {
                rules[formItem.key] = {
                  pattern: new RegExp(formItem.exp),
                  message: formItem.message,
                };
              }
              return {
                label: formItem.name,
                props: formItem.key,
                required: formItem.required,
              };
            });
            item.form = {
              items,
              rules,
            };
          }
        });
        pay_type.value = res;
        selectChannel.value = res[0];
        console.log("selectChannel"+selectChannel.value);
        
        state.minThirdPay = res[0].minAmount;
        sessionStorage.setItem('platId', selectChannel.value.id);
        zhifu.value = true;
        show.value = true;
      })
      .catch(() => {
        zhifu.value = true;
      });
  } else if (zhifuwz.value == 0) {
    dialogVisible.value = true;
  } else if (zhifuwz.value == 2) {
    showUSTD.value = true;
    await UstdPayInfo().then(async res => {
      Object.assign(usdtInfo, res);
      if(res.coinType){
        coinType.value = res.coinType
        console.log(coinType.value);
        
      }
      state.minThirdPay = res.minPayAmount * res.exchangeRate;
    });
  }
};

/* 复原状态值 */
watch(show, () => {
  if (!show.value) {
    const resetValues = [captcha, coupon, tips];
    resetValues.forEach(value => {
      value.value = '';
    });
    state.coupon = 0;
  }
});
const payState = reactive({
  counting: false,
  codeFetching: false,
  payFetching: false,
});

//支付取消
const payCancel = () => {
  dialogVisible.value = false;
  const resetValues = [captcha, coupon, tips];
  resetValues.forEach(value => {
    value.value = '';
  });
  state.coupon = 0;
};
//支付
const paySubmit = () => {
  if (!captcha.value) {
    return message.error(lang.v.pwdNotNull);
  }
  if (payState.payFetching) {
    return;
  }
  payState.payFetching = true;
  charge({
    couponCode: coupon.value,
    id: pay_money_id.value,
    rechargeType: 'TALENT_CARD',
    payType: 'H5',
    platformType: 'WALLET',
    captcha: captcha.value,
  })
    .then(res => {
      if (res) {
        message.success(lang.v.optSuccess);
        dialogVisible.value = false;
      } else {
        message.error(lang.v.OrderErr);
      }
      captcha.value = '';
    })
    .finally(() => {
      payState.payFetching = false;
    });
};
const getCheckCode = () => {
  if (payState.codeFetching || payState.counting) {
    return;
  }
  payState.codeFetching = true;
  text.value = lang.v.load;
  checkCodeRequest_wallet_pay({ type: 'WALLET_PAY' })
    .then(resp => {
      if (resp.data?.code) {
        payState.counting = true;
        let time = 90; //定义剩下的秒数
        const timer = setInterval(() => {
          if (time === 0) {
            clearInterval(timer);
            payState.counting = false;
            text.value = lang.v.text11;
          } else {
            time--;
            text.value = `${time}S`;
          }
        }, 1000);
      } else {
        message.success(lang.v.optSuccess);
        text.value = lang.v.text11;
      }
    })
    .catch(() => {
      text.value = lang.v.text11;
    })
    .finally(() => {
      payState.codeFetching = false;
    });
};

const check = item => {
  check_id.value = '';
  goldId.value = item.type;
  types_login.value = [];
  money.value = [];
  money_pay.value.money = '';
  money_pay_senior.value.money = '';
  sessionStorage.clear();
  sessionStorage.setItem('intelligent_id', goldId.value);
  sessionStorage.setItem('money', JSON.stringify(money_pay.value.money));
  sessionStorage.setItem(
    'money_senior',
    JSON.stringify(money_pay_senior.value.money)
  );
  money_pay.value.money = ref(JSON.parse(sessionStorage.getItem('money')));
  money_pay_senior.value.money = ref(
    JSON.parse(sessionStorage.getItem('money_senior'))
  );
  if (goldId.value == 1) {
    card_info().then(async res => {
      firstItem_types.value = [...res][0];
      types_login.value.push(firstItem_types.value);
    });
    card_pay().then(async res => {
      firstItem_cardpay.value = [...res][0];
      money.value.push(firstItem_cardpay.value);
    });
  }
  if (goldId.value == 2) {
    card_info().then(async res => {
      firstItem_types.value = [...res][1];
      types_login.value.push(firstItem_types.value);
    });
    card_pay().then(async res => {
      firstItem_cardpay.value = [...res][1];
      money.value.push(firstItem_cardpay.value);
    });
  }
};
const types_login = ref([]);
const pay_money_id = ref('');
const type = ref([
  {
    type: 1,
    name: '***',
  },
  {
    type: 2,
    name: '***',
  },
]);
let currentCard = null;

const choose = e => {
  currentCard = e;
  check_id.value = e.id;
  goldId.value = Number(sessionStorage.getItem('intelligent_id'));
  sessionStorage.setItem('pay_money_id', JSON.stringify(e.id));
  pay_money_id.value = JSON.parse(sessionStorage.getItem('pay_money_id'));

  if (goldId.value == 1) {
    sessionStorage.setItem('money', JSON.stringify(e.price));
    money_pay.value.money = ref(JSON.parse(sessionStorage.getItem('money')));
  } else {
    sessionStorage.setItem('money_senior', JSON.stringify(e.price));
    money_pay_senior.value.money = ref(
      JSON.parse(sessionStorage.getItem('money_senior'))
    );
  }
};
const money_pay = ref({
  money: '',
});
const money_pay_senior = ref({
  money: '',
});
const check_pay = e => {
  payment.value = e.id;
  nowIndex.value = e.id;
  platformType.value = e.platformType;
  zhifuwz.value = e.id;
};
const warning1 = lang.v.warning1;
const success1 = lang.v.success1;

const confirm = async () => {
  // 如果输入框的数据成功绑定，则显示出来，如果没有，则提示错误
  if (addcode.parentInvitationCode == '') {
    message.error(warning1);
  } else {
    parentInvitationCode.value = addcode.invitationCode;
    await invitebind(addcode).then(async res => {
      if (res) {
        message.success(success1);
        await inviteInfo().then(res => {
          parentInvitationCode.value = res.parentInvitationCode;
        });
      } else {
        parentInvitationCode.value = null;
        return;
      }
    });
  }
};
//邀请码弹窗关闭事件
const onCloseCodeDialog = () => {
  addcode.invitationCode = '';
};
const pays = reactive([
  {
    id: 0,
    platformType: 'WALLET',
    name: 'WALLET',
  },
  {
    id: 1,
    platformType: 'THIRD_PARTY',
    name: 'Third pay',
  },
  {
    id: 2,
    platformType: 'USTD',
    name: 'USTD',
  },
]);

const money = ref([]);

var firstItem_types = ref([]);
var firstItem_cardpay = ref([]);
let email = '';
const parentInvitationCode = ref('');
onMounted(async () => {
  await inviteInfo().then(res => {
    parentInvitationCode.value = res.parentInvitationCode;
  });
  channelForm.value = getObject('payInfo');
  try {
    const res = await personal();
    state.wallet = res.balance;
  } catch (err) {
    console.log(err);
  }
  const res = await getUserInfo(userinfo.value.userId);
  email = res.email;
  card_pay().then(async res => {
    type.value = res;
    goldId.value = Number(
      sessionStorage.getItem('intelligent_id') || type.value[0].type
    );
    if (goldId.value == 1) {
      card_info().then(async res => {
        firstItem_types.value = res[0];
        types_login.value.push(firstItem_types.value);
        console.log(types_login.value);
      });
      firstItem_cardpay.value = type.value[0];
    } else {
      card_info().then(async res => {
        firstItem_types.value = res[1];
        types_login.value.push(firstItem_types.value);
        console.log(types_login.value);
      });
      firstItem_cardpay.value = type.value[1];
    }
    money.value = [firstItem_cardpay.value];
  });
  try {
    const userInfoString = localStorage.getItem('userInfo');
    if (userInfoString && userInfoString !== 'undefined') {
      userinfo.value = JSON.parse(userInfoString);
    }
  } catch (e) {
    console.error('Error parsing userInfo from localStorage:', e);
  }
  sessionStorage.setItem('money', JSON.stringify(money_pay.value.money));
  sessionStorage.setItem(
    'money_senior',
    JSON.stringify(money_pay_senior.value.money)
  );
  money_pay.value.money = ref(JSON.parse(sessionStorage.getItem('money')));
  money_pay_senior.value.money = ref(
    JSON.parse(sessionStorage.getItem('money_senior'))
  );
});
</script>
<style lang="less" scoped>
.nav {
  display: flex;
  justify-content: space-between;
  padding-top: 0.14rem;
  position: fixed;
  width: 100%;
  top: 0.64rem;

  .left {
    display: flex;
    margin-left: 3.73%;

    .pictrue {
      width: 0.4rem;
      height: 0.4rem;
      border-radius: 50%;

      img {
        width: 0.4rem;
        height: 0.4rem;
        border-radius: 50%;
      }
    }

    .info {
      margin-left: 0.12rem;

      .name {
        height: 0.2rem;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #444444;
        line-height: 0.2rem;
      }

      .userId {
        height: 0.2rem;
        font-size: 0.12rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #858c9f;
        line-height: 0.2rem;
      }
    }
  }

  .right {
    width: 0.4rem;
    height: 0.4rem;
    margin-right: 2%;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.main {
  width: 92.5%;
  position: fixed;
  top: 1.34rem;
  bottom: 0.14rem;
  left: 50%;
  transform: translate(-50%);
  border-radius: 0.08rem;
  background-color: #ffffff;
  padding-top: 0.14rem;
  padding-bottom: 0.14rem;

  .tab {
    width: 91.93%;
    height: 0.44rem;
    background: rgba(133, 140, 159, 0.06);
    margin: auto;
    border-radius: 0.06rem;
    display: flex;

    .ordinary {
      width: 46.71%;
      height: 0.3rem;
      text-align: center;
      line-height: 0.3rem;
      font-size: 0.13rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #858c9f;
      margin-left: 2.19%;
      margin-top: 0.07rem;
    }

    .act-ordinary {
      width: 46.71%;
      height: 0.3rem;
      text-align: center;
      line-height: 0.3rem;
      font-size: 0.13rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #444444;
      margin-left: 2.19%;
      background: #ffffff;
      box-shadow: 0rem 0rem 0.08rem 0rem rgba(133, 140, 159, 0.15);
      border-radius: 0.04rem;
      margin-top: 0.07rem;
    }
  }

  .become_login {
    width: 92.5%;
    margin: auto;
    margin-top: 0.04rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .type_login {
      width: 100%;
      padding-bottom: 0.06rem;

      .type_top {
        width: 100%;
        height: 0.12rem;
      }

      .card_login {
        display: flex;
        justify-content: center;

        .card_logo {
          width: 0.24rem;
          height: 0.24rem;
          margin-top: 0.02rem;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .card_name {
          height: 0.28rem;
          font-size: 0.2rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #5eb15c;
          line-height: 0.28rem;
        }
      }

      .multiple {
        height: 0.2rem;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #858c9f;
        line-height: 0.16rem;
        text-align: center;
        margin-top: 0.2rem;
      }

      .task {
        margin-left: 0.24rem;
        margin-top: 0.2rem;

        .completion {
          display: flex;
          justify-content: left;

          .complete {
            width: 0.2rem;
            height: 0.2rem;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .task_describe {
            width: 2.5rem;
            font-size: 0.14rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #444444;
            line-height: 0.2rem;
            margin-left: 0.06rem;
          }
        }

        p {
          font-size: 0.14rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #858c9f;
          line-height: 0.2rem;
          margin-left: 0.26rem;
        }
      }
    }
  }

  .check_pay {
    width: 100%;
    margin: auto;
    margin-top: 0.04rem;

    .type_pay {
      .pay_type {
        width: 91.93%;
        height: 1.02rem;
        background: #ffffff;
        border-radius: 0.06rem;
        opacity: 1;
        margin: auto;
        margin-top: 0.12rem;
        border: 0.02rem solid rgba(133, 140, 159, 0.1);

        .card_type {
          display: flex;
          width: 100%;
          margin-top: 0.14rem;

          .card_name {
            height: 0.22rem;
            font-size: 0.16rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #5eb15c;
            line-height: 0.22rem;
            margin-left: 4.39%;
          }

          .card_time {
            height: 0.18rem;
            font-size: 0.13rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #858c9f;
            line-height: 0.18rem;
            margin-top: 0.04rem;
            margin-left: 0.12rem;
          }
        }

        .money {
          display: flex;
          justify-content: space-between;
          margin-top: 0.1rem;

          .left_money {
            margin-left: 4.39%;
          }

          .check_it {
            width: 1.38rem;
            height: 0.32rem;
            background: #5eb15c;
            border-radius: 0.06rem;
            font-size: 0.14rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 0.32rem;
            text-align: center;
            margin-top: 0.06rem;
            margin-right: 4.39%;
          }

          .checked {
            border: 0.01rem solid rgba(133, 140, 159, 0.1);
            background-color: rgba(133, 140, 159, 0.1);
            border-radius: 0.06rem;
            color: rgba(133, 140, 159, 1);
          }
        }
      }
    }
  }
}

.pay_method {
  width: 91.93%;
  background: #ffffff;
  border-radius: 0.06rem;
  opacity: 1;
  margin: auto;
  margin-top: 0.14rem;
  padding-bottom: 0.14rem;
  border: 0.02rem solid rgba(133, 140, 159, 0.1);

  .title {
    width: 100%;
    height: 0.18rem;
    font-size: 0.13rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #444444;
    line-height: 0.15rem;
    text-align: center;
    margin-top: 0.2rem;
  }

  .payment {
    display: flex;
    justify-content: center;
    margin-top: 0.12rem;

    .box {
      width: 0.88rem;
      height: 0.32rem;
      display: flex;
      justify-content: space-around;
      border-radius: 0.06rem;
      opacity: 1;
      border: 0.01rem solid #5eb15c;

      .pay_logo {
        width: 0.16rem;
        height: 0.16rem;
        margin-left: 0.12rem;
        margin-top: 0.07rem;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .pay_name {
        height: 0.32rem;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #5eb15c;
        line-height: 0.32rem;
        margin-right: 0.12rem;
      }
    }

    .box_zfb {
      width: 0.88rem;
      height: 0.32rem;
      display: flex;
      justify-content: center;
      border-radius: 0.06rem;
      opacity: 1;
      border: 0.01rem solid #1296db;

      .pay_name {
        height: 0.32rem;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #1296db;
        line-height: 0.32rem;
        margin: 0 0.06rem;
      }
    }

    .box_none {
      width: 0.88rem;
      height: 0.32rem;
      display: flex;
      justify-content: space-between;
      border-radius: 0.06rem;
      opacity: 1;
      border: 0.01rem solid rgba(133, 140, 159, 0.5);

      .pay_name {
        height: 0.32rem;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: rgba(133, 140, 159, 0.5);
        line-height: 0.32rem;
        margin-right: 0.12rem;
      }
    }
  }

  .pay {
    width: 100%;
    height: 0.18rem;
    font-size: 0.13rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #444444;
    line-height: 0.18rem;
    text-align: center;
    margin-top: 0.16rem;
  }

  .pay_money {
    width: 100%;
    height: 0.34rem;
    font-size: 0.24rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #444444;
    line-height: 0.34rem;
    text-align: center;
    margin-top: 0.06rem;
  }

  .wx_meet {
    .meet {
      width: 1.26rem;
      height: 1.26rem;
      margin: auto;
      margin-top: 0.16rem;
      background-color: #5eb15c;
    }

    .agreement {
      margin-top: 0.16rem;
      padding-bottom: 0.2rem;

      p {
        width: 100%;
        text-align: center;
        font-size: 0.12rem;
        margin: 0;
      }

      p:nth-child(2) {
        color: rgba(94, 177, 92, 1);
      }
    }
  }

  .zfb_meet {
    .fork {
      width: 50.23%;
      height: 0.32rem;
      margin: auto;
      margin-top: 0.16rem;
      display: flex;
      justify-content: center;
      background-color: rgba(237, 147, 30, 1);
      border-radius: 0.06rem;

      .skip {
        width: 0.16rem;
        height: 0.16rem;

        img {
          width: 100%;
          height: 100%;
          margin-top: 0.08rem;
        }
      }

      .text {
        height: 0.32rem;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 0.32rem;
        margin-left: 0.06rem;
      }

      :deep(.van-loading__spinner) {
        margin-top: 0.03rem;
      }
    }

    .agreement {
      margin-top: 0.16rem;
      padding-bottom: 0.2rem;

      p {
        width: 100%;
        text-align: center;
        font-size: 0.12rem;
        margin: 0;
      }

      p:nth-child(2) {
        color: rgba(237, 147, 30, 1);
      }
    }
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 80%;
  border-radius: 0.1rem;
  padding: 0.2rem 0;
  background-color: #fff;

  .channels {
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow-x: scroll;
    width: 100%;
    font-size: 0.14rem;
    color: #111;
    align-items: center;

    > .item {
      margin: 0 0.03rem;
      text-align: center;
      border-radius: 0.14rem;
      color: #ffffff;
      padding: 0 0.24rem;
      line-height: 0.28rem;

      &.active {
        background-color: #ed931e;
      }

      > .logo {
        width: 0.24rem;
        height: 0.24rem;
      }

      > .name {
        margin-left: 0.02rem;
      }
    }
  }

  .form-container {
    padding: 0 0.14rem;
    margin-top: 0.2rem;
    font-family: Arial, sans-serif;

    .title {
      line-height: 0.2rem;
      font-size: 0.14rem;
      color: #222;
      text-align: center;
    }

    .channel-form {
      margin-top: 0.12rem;

      .el-form-item {
        margin-bottom: 0.2rem;
      }

      .channel-input {
        font-size: 0.12rem;
        color: #222;
        height: 0.42rem;
        border-radius: 0.21rem;
        background-color: #f6f7f8;
        padding: 0 0.1rem;

        :deep(.el-input__wrapper) {
          background: none;
          box-shadow: none !important;
        }
      }
    }
  }

  .gopay {
    width: 100%;
    height: 0.4rem;
    text-align: center;
    line-height: 0.4rem;
    color: #ffffff;
    margin: auto;
    margin-top: 0.1rem;
    background-color: rgba(237, 147, 30, 1);
    border-radius: 0.68rem;
  }
}

.inputxt {
  height: 0.48rem;
  width: 85%;
  display: flex;
  align-items: center;
  border-radius: 0.27rem;
  background-color: #ffffff;

  img {
    margin-right: 0.06rem;
    width: 0.24rem;
  }

  .input {
    margin-left: 0.12rem;
    font-size: 0.1rem;
  }

  .checktext {
    font-size: 0.12rem;
    margin-left: 0.12rem;

    p {
      line-height: 0.2rem;
      white-space: nowrap;
      color: #ed931e;
    }
  }
}
.coinTypeSelect{
    margin-top: 0.12rem;
    padding: 0 0.14rem;
  }
.payment-form {
  padding: 0 0.14rem;
  margin-top: 0.2rem;

  .title {
    font-size: 0.14rem;
    line-height: 0.2rem;
    text-align: center;
  }

  .amount-info {
    margin-top: 0.12rem;
    height: 0.42rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f6f7f8;
    padding: 0 0.14rem;
    box-sizing: border-box;
    border-radius: 0.21rem;
    font-size: 0.12rem;

    .checkbox-container {
      position: relative;
      padding-left: 0.25rem;
      padding-bottom: 0.2rem;
      cursor: pointer;
      font-size: 0.1rem;
      user-select: none;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .checkbox-checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 0.17rem;
        width: 0.17rem;
        background-color: transparent;
        border-radius: 50%;
        border: 0.02rem solid #888888;

        &:after {
          content: '';
          position: absolute;
          display: block;
          left: 0.06rem;
          top: 0.03rem;
          width: 0.04rem;
          height: 0.07rem;
          border: solid #888888;
          border-width: 0 0.02rem 0.02rem 0;
          transform: rotate(45deg);
        }
      }

      input:checked ~ .checkbox-checkmark {
        border-color: #3bc117;

        &:after {
          border-color: #3bc117;
        }
      }
    }
  }

  .tips {
    font-size: 0.12rem;
    color: #888;
    padding: 0.1rem 0;
    text-align: center;
  }

  .container {
    margin-bottom: 0.2rem;
  }

  .input-container {
    height: 0.42rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f6f7f8;
    padding: 0 0.14rem;
    border-radius: 0.21rem;

    input {
      border: none;
      outline: none;
      background-color: #f6f7f8;
      flex: 1;
      padding: 0 0.1rem;
      font-size: 0.12rem;
      border-radius: 1rem;
    }

    .coder {
      background-color: #ed931e;
      border-radius: 0.15rem;
      color: #eeeeee;
      padding: 0 0.1rem;
      font-size: 0.12rem;
      line-height: 0.3rem;
    }
  }

  .pay-info {
    font-size: 0.12rem;
    color: #888;
    margin-top: 0.2rem;
    text-align: center;
  }

  .dialog-footer {
    display: flex;
    justify-content: center;
    align-content: space-around;

    .cancel {
      border-radius: 0.15rem;
      background-color: #f6f7f8;
      width: 1rem;
      padding: 0.05rem 0;
    }

    .submit {
      margin-left: 0.2rem;
      background-color: #ed931e;
      color: #eee;
      border-radius: 0.15rem;
      width: 1rem;
    }
  }
}
.dialog-box {
  width: 2.6rem;
  margin: auto;
  margin-top: 0.12rem;
  margin-bottom: 0.12rem;

  input {
    color: #333;
    width: 2.6rem;
    height: 0.44rem;
    line-height: 0.44rem;
    border-radius: 0.1rem;
    border: none;
    font-size: 0.14rem;
    padding-left: 0.1rem;
    background-color: rgba(246, 247, 249, 1);
  }

  input::-webkit-input-placeholder {
    font-size: 0.14rem;
    color: rgba(133, 140, 159, 0.5);
  }
}
</style>
